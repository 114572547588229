import Box from 'components/commons/Box'
import ActionButton from 'components/commons/Button/Action'
import ModalDrawer from 'components/domains/User/ModalDrawer'
import appConfig from 'configs/app'
import htmlParse from 'html-react-parser'
import { useTranslation } from 'react-i18next'

export default function PaymentInfoModal({
  paymentItem,
  openInfo,
  setCloseInfo,
}) {
  const { t } = useTranslation()
  return (
    <ModalDrawer
      title={paymentItem?.name}
      isOpen={openInfo}
      onClose={setCloseInfo}
      sxDialogContainer={{
        '& .MuiDialogContent-root': {
          padding: '0px',
        },
        '& .MuiDialog-paper': {
          width: '500px',
        },
      }}
      sxDrawerContainer={{ padding: '16px 0px' }}
    >
      <Box style={{ fontSize: '12px', marginBottom: '16px' }}>
        {htmlParse(String(paymentItem?.info))}
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          fontSize: '12px',
        }}
      >
        {paymentItem?.code === 'TIPTIP_COIN' && (
          <ActionButton
            buttonVariant="outlined"
            sx={{
              padding: '12px 24px',
              width: '100%',
              marginRight: '8px',
            }}
            onClick={() => {
              window.open(appConfig.coinGuidelineUrl, '_blank')
            }}
          >
            {t('paymentMethodV2WidgetShowCaseCoinBtn')}
          </ActionButton>
        )}
        <ActionButton
          sx={{
            padding: '12px 24px',
            width: '100%',
            marginLeft: '8px',
          }}
          onClick={setCloseInfo}
        >
          {t('back')}
        </ActionButton>
      </Box>
    </ModalDrawer>
  )
}

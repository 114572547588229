import { useQuery } from '@tanstack/react-query'
import { useCommunityStore } from 'stores/domains/Social'
import { isGuestMode } from 'helpers/cookie'
import { getCommunityInfo } from 'services/social'

export const useCommunityInfo = () => {
  const { setCommunityInfo } = useCommunityStore((state) => ({
    setCommunityInfo: state.setCommunityInfo,
  }))

  return useQuery({
    queryKey: ['community-info'],
    queryFn: async () => {
      if (isGuestMode()) return {}
      try {
        const response = await getCommunityInfo()
        return response.data.data
      } catch (error) {
        throw error
      }
    },
    enabled: false,
    retry: false,
    onSuccess: (data) => {
      setCommunityInfo(data)
    },
  })
}

import apiClient from 'helpers/api-client'
import { getIdToken } from 'helpers/auth'
import { isGuestMode } from 'helpers/cookie'

function getUrlForEvent({
  originalUrl,
  eventStatus,
  categoryId,
  priceType,
}) {
  let eventStatusVal = eventStatus

  if (!eventStatus || !eventStatus.length)
    return originalUrl + `?${categoryId}${priceType}`

  if (!Array.isArray(eventStatusVal)) {
    eventStatusVal = [eventStatusVal]
  }

  return `${originalUrl}${eventStatusVal
    .map(
      (filterItem, i) =>
        `${i === 0 ? '?' : ''}filter_status=${filterItem}`,
    )
    .join('&')}${categoryId}${priceType}`
}

interface IEventRecommendationParams {
  page: number
  limit: number
  key?: string
  category_id?: string | string[]
  type?: string
  content_type?: string
  event_status?: string[]
  sources?: string
  status?: string | string[] // free || premium
}

export const getContentRecommendation = async ({
  page,
  limit,
  key,
  category_id = '',
  type,
  content_type = '',
  event_status = [],
  status,
}: IEventRecommendationParams) => {
  let url = isGuestMode()
    ? '/recommendation/guest/v1/content'
    : '/recommendation/v1/content'

  if (type === 'event') {
    url = getUrlForEvent({
      originalUrl: url,
      eventStatus: event_status,
      categoryId: category_id,
      priceType: content_type,
    })
  }

  return await apiClient({
    url: url,
    method: 'GET',
    token: isGuestMode() ? null : getIdToken(),
    params: {
      q: key,
      page,
      limit,
      type,
      sources: !isGuestMode() ? 'recommendation' : '',
      status,
      category_id,
    },
  })
}

export const getProvinceListForHome = async () => {
  return await apiClient({
    url: `/recommendation/guest/v1/homepage/provinces`,
    method: 'GET',
  }).catch((error) => {
    return Promise.reject(error)
  })
}

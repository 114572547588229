import { SxProps, styled } from '@mui/material'
import StateImage from 'components/commons/StateImage'
import Box from 'components/commons/Box'
import {
  ErrorCountdown,
  ErrorDesc,
  ErrorLabel,
} from 'containers/domains/Wallet/DirectPaymentModal/styled'
import Stack from '../Stack'
import { useTranslation } from 'react-i18next'
import VoucherErrorContent from 'components/domains/Wallet/VoucherError'
import Attention from 'components/commons/Attention'
import { getIdToken } from 'helpers/auth'
import ActionButton from '../Button/Action'
import { IllustrationNetworkError } from 'svgs/illustrations'
import { useState } from 'react'

const StyledStateImage = ({ type }) => (
  <StateImage
    type={type}
    sx={{
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '24px',
    }}
  />
)

const ContainerBtn = styled(Stack)({
  display: 'block',
  width: '100%',
  flexDirection: 'row',
  marginTop: '24px',
  justifyContent: 'center',
})

export default function ErrorResponseCode({
  code,
  showCTA,
  onCTA,
  data,
  sxContainer,
  disabled,
}: {
  code?: string
  showCTA?: boolean
  onCTA?: Function | any
  data?: any
  sxContainer?: SxProps
  disabled?: boolean
}) {
  const { t } = useTranslation()
  const [countDownToRetry, setCountDownToRetry] = useState(0)

  const handleCountdownClick = () => {
    setCountDownToRetry(3)
    const timer = setInterval(() => {
      setCountDownToRetry((prevCount) => {
        if (prevCount <= 1) {
          clearInterval(timer)
          onCTA('retry')
          return 0
        }
        return prevCount - 1
      })
    }, 1000)
  }
  switch (code) {
    case 'INSUFFICIENT_QUOTA':
      return (
        <Box sx={sxContainer}>
          <StyledStateImage type="ticket-not-available" />
          <ErrorLabel>
            {t('eventTicketSelectionSoldOutTitle')}
          </ErrorLabel>
          <ErrorDesc>
            {t('eventTicketSelectionSoldOutDescription')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('browse_events')}
              >
                {t('eventTicketSelectionSeeOtherEvent')}
              </ActionButton>
              <ActionButton
                style={{ marginTop: '16px' }}
                fullWidth
                buttonVariant="outlined"
                onClick={() => onCTA('back_to_event')}
              >
                {t('eventParticipantFormBackToEvent')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'PRODUCT_UNAVAILABLE':
      return (
        <Box>
          <StyledStateImage type="calendar" />
          <ErrorLabel>{t('eventUnpublishedTitle')}</ErrorLabel>
          <ErrorDesc>{t('eventUnpublishedDescription')}</ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('browse_events')}
              >
                {t('eventUnpublishedButtonLabel')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'SALE_NOT_STARTED':
      return (
        <Box>
          <StyledStateImage type="date-time" />
          <ErrorLabel>
            {t('eventDetailsErrorSaleNotStartedTitle')}
          </ErrorLabel>
          <ErrorDesc>
            {t('eventDetailsErrorSaleNotStartedSubtitle')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton fullWidth onClick={() => onCTA('close')}>
                {t('close')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'USER_OVERBOOKED':
      return (
        <Box>
          <StyledStateImage type="ticket-not-available" />
          <ErrorLabel>{t('eventUserOverbookedTitle')}</ErrorLabel>
          <ErrorDesc>{t('eventUserOverbookedDescription')}</ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton fullWidth onClick={() => onCTA('close')}>
                {t('close')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'INVALID_SALES_PERIOD':
      return (
        <Box>
          <StyledStateImage type="calendar" />
          <ErrorLabel>
            {t('eventTicketSelectionSalesEndedTitle')}
          </ErrorLabel>
          <ErrorDesc>
            {t('eventTicketSelectionSalesEndedDescription')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('back_to_event')}
              >
                {t('eventParticipantFormBackToEvent')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'ID_NUMBER_ALREADY_USED':
      return (
        <Box>
          <StyledStateImage type="user-alert" />
          <ErrorLabel>
            {t('key_duplicate_id_number_title')}
          </ErrorLabel>
          <ErrorDesc>
            {t('key_duplicate_id_number_desc', {
              id_number: data?.id_card?.join(', '),
            })}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton fullWidth onClick={() => onCTA('close')}>
                {t('key_too_many_request_share_cta')}
              </ActionButton>
              <ActionButton
                style={{ marginTop: '16px' }}
                fullWidth
                buttonVariant="outlined"
                onClick={() => onCTA('contact_cs')}
              >
                {t('contactCs')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'NOT_ENOUGH_QUOTA':
      return (
        <Box>
          <StyledStateImage type="ticket-not-available" />
          <ErrorLabel>
            {t('eventDetailsErrorQuantityExceedsQuotaTitle')}
          </ErrorLabel>
          <ErrorDesc>
            {t('eventDetailsQuantityExceedsQuotaSubtitle')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('refresh')}
              >
                {t('refresh')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'FAIL_CHECKOUT':
      return (
        <Box>
          <StyledStateImage type="system-error" />
          <ErrorLabel>
            {t('vaPaymentInstructionWidgetErrorTitle')}
          </ErrorLabel>
          <ErrorDesc>
            {t('key_payment_general_error_after_checkout_message')}
          </ErrorDesc>
          {countDownToRetry !== 0 && (
            <ErrorCountdown>
              {' '}
              Dalam waktu {countDownToRetry}
            </ErrorCountdown>
          )}
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                disabled={disabled || countDownToRetry !== 0}
                onClick={() => {
                  handleCountdownClick()
                }}
              >
                {t('retry')}
              </ActionButton>

              <ActionButton
                style={{ marginTop: '16px' }}
                fullWidth
                buttonVariant="outlined"
                disabled={disabled || countDownToRetry !== 0}
                onClick={() => onCTA('trx_history')}
              >
                {getIdToken()
                  ? t('key_check_transaction_history')
                  : t('key_back_to_event_details')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'FAIL':
      return (
        <Box>
          <StyledStateImage type="system-error" />
          <ErrorLabel>
            {t('paymentWidgetErrorWithRetryTitle')}
          </ErrorLabel>
          <ErrorDesc>
            {t('paymentWidgetErrorWithRetryDesc')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton fullWidth onClick={() => onCTA('close')}>
                {t('retry')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'INSUFFICIENT_BALANCE_COIN':
      return (
        <Box>
          <StyledStateImage type="error" />
          <ErrorLabel>
            {t('paymentWidgetInsufficientCoinBalanceTitle')}
          </ErrorLabel>
          <ErrorDesc>
            {t('paymentWidgetInsufficientCoinBalanceDesc')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton fullWidth onClick={() => onCTA('topup')}>
                {t('topUpCoinButtonTitle')}
              </ActionButton>
              <ActionButton
                style={{ marginTop: '16px' }}
                fullWidth
                buttonVariant="outlined"
                onClick={() => onCTA('back')}
              >
                {t('back')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'BAD_REQUEST_COIN':
      return (
        <Box>
          <StyledStateImage type="error" />
          <ErrorLabel>{t('topUpCoinExceededLimitTitle')}</ErrorLabel>
          <ErrorDesc sx={{ textTransform: 'capitalize' }}>
            {data}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton fullWidth onClick={() => onCTA('close')}>
                {t('back')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'NOT_FOUND':
      return (
        <Box>
          <StyledStateImage type="no-respond" />
          <ErrorLabel>
            {t('vaPaymentInstructionWidgetCanceledTitle')}
          </ErrorLabel>
          <ErrorDesc>
            {t('vaPaymentInstructionWidgetCanceledDesc')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton fullWidth onClick={() => onCTA('close')}>
                {t('close')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'PRODUCT_UNAVAILABLE':
      return (
        <Box>
          <StyledStateImage type="calendar" />
          <ErrorLabel>{t('eventUnpublishedTitle')}</ErrorLabel>
          <ErrorDesc>{t('eventUnpublishedDescription')}</ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton fullWidth onClick={() => onCTA('close')}>
                {t('eventUnpublishedButtonLabel')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'SESSION_FULLY_BOOKED':
      return (
        <Box>
          <StyledStateImage type="unhappy_booking" />
          <ErrorLabel>
            {t('sessionFullyBookedWidgetTitle')}
          </ErrorLabel>
          <ErrorDesc>
            {t('purchaseTicketBottomSheetSessionFullyBookedSubtitle')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton fullWidth onClick={() => onCTA('close')}>
                {t('refresh')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'GUEST_PENDING_TRANSACTION':
      return (
        <Box>
          <StyledStateImage type="guest_pending" />
          <ErrorLabel>{t('key_previous_order_not_paid')}</ErrorLabel>
          <ErrorDesc>
            {data?.body || t('vaPaymentInstructionWidgetErrorDesc')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                disabled={disabled}
                fullWidth
                onClick={() => onCTA('continue')}
              >
                {t(
                  'guestPurchasePendingPaymentCompletePaymentButton',
                )}
              </ActionButton>
              {!getIdToken() && (
                <ActionButton
                  style={{ marginTop: '16px' }}
                  disabled={disabled}
                  fullWidth
                  buttonVariant="outlined"
                  onClick={() => onCTA('cancel')}
                >
                  {t('transactionHistoryScreenCancelBtn')}
                </ActionButton>
              )}
            </ContainerBtn>
          )}
        </Box>
      )
    case 'INVALID_VOUCHER':
      return (
        <Box>
          <VoucherErrorContent />
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton fullWidth onClick={() => onCTA('close')}>
                {t('understand')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'BOOKING_EXPIRED':
      return (
        <Box>
          <StyledStateImage type="no-respond" />
          <ErrorLabel>{t('key_time_is_up_title')}</ErrorLabel>
          <ErrorDesc>{t('key_time_is_up_desc')}</ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                disabled={disabled}
                fullWidth
                onClick={() => onCTA('select_ticket')}
              >
                {t('eventTicketSelectTicketButton')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'EDIT_CONFIRMATION':
      return (
        <Box>
          <StyledStateImage type="user-alert" />
          <ErrorLabel>
            {t('key_change_contact_info_title')}
          </ErrorLabel>
          <ErrorDesc>{t('key_change_contact_info_desc')}</ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                disabled={disabled}
                fullWidth
                onClick={() => onCTA('edit_booking')}
              >
                {t('key_change_contact_info_cta')}
              </ActionButton>
              <ActionButton
                style={{ marginTop: '16px' }}
                disabled={disabled}
                fullWidth
                buttonVariant="outlined"
                onClick={() => {
                  onCTA('continue_booking')
                }}
              >
                {t('key_cancel')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'CANCEL_CONFIRMATION':
      return (
        <Box>
          <StateImage
            id="c-cancel-confirmation-error-modal-img"
            sx={{
              display: 'block',
              margin: 'auto',
              marginBottom: '24px',
            }}
            type="ticket-not-available"
          />
          <ErrorLabel>
            {t('key_order_will_canceled_title')}
          </ErrorLabel>
          <ErrorDesc>{t('key_order_will_canceled_desc')}</ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                id="b-continue-booking-error-modal"
                disabled={disabled}
                fullWidth
                onClick={() => onCTA('continue_booking')}
              >
                {t('key_continue_order_btn')}
              </ActionButton>
              <ActionButton
                style={{ marginTop: '16px' }}
                id="b-cancel-booking-error-modal"
                disabled={disabled}
                fullWidth
                buttonVariant="outlined"
                onClick={() => {
                  onCTA('cancel_booking')
                }}
              >
                {t('transactionHistoryScreenCancelBtn')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'BOOKING_ONGOING':
      return (
        <Box>
          <StateImage
            id="c-ongoing-booking-error-modal-img"
            sx={{
              display: 'block',
              margin: 'auto',
              marginBottom: '24px',
            }}
            type="ticket-not-available"
          />
          <ErrorLabel>{t('key_pending_order')}</ErrorLabel>
          <ErrorDesc>{t('key_pending_order_desc')}</ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                id="b-finish-booking-error-modal"
                disabled={disabled}
                fullWidth
                onClick={() => onCTA('finish_booking')}
              >
                {t('key_complete_Order')}
              </ActionButton>
              <ActionButton
                style={{ marginTop: '16px' }}
                id="b-create-new-booking-error-modal"
                disabled={disabled}
                fullWidth
                buttonVariant="outlined"
                onClick={() => onCTA('create_new_booking')}
              >
                {t('key_create_new_order')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'CHECKOUT_INVALID_STATUS':
      return (
        <Box>
          <StyledStateImage type="session-almost-full" />
          <ErrorLabel>{t('key_order_invalid')}</ErrorLabel>
          <ErrorDesc>{t('key_order_invalid_desc')}</ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              {
                <ActionButton
                  disabled={disabled}
                  fullWidth
                  onClick={() => onCTA('reorder')}
                >
                  {t('key_reorder_btn')}
                </ActionButton>
              }
            </ContainerBtn>
          )}
        </Box>
      )
    //card payment
    case 'AUTHENTICATION_FAILED':
      return (
        <Box>
          <StyledStateImage type="card-failed" />
          <ErrorLabel>{t('key_authentication_failed')}</ErrorLabel>
          <ErrorDesc>{t('key_authentication_failed_desc')}</ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('check_card_detail')}
              >
                {t('key_check_card_details')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )

    //INVALID_JSON_FORMAT && API_VALIDATION_ERROR && VALIDATION_ERROR && AUTHENTICATION_REQUIRED_ERROR
    //CREDIT_CARD_TOKEN_NOT_FOUND_ERROR&&REQUEST_FORBIDDEN_ERROR
    case 'API_VALIDATION_ERROR':
      return (
        <Box>
          <StyledStateImage type="system-error" />
          <ErrorLabel>{t('key_payment_failed')}</ErrorLabel>
          <ErrorDesc>{t('key_payment_failed_desc')}</ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton fullWidth onClick={() => onCTA('close')}>
                {t('key_change_payment_method')}
              </ActionButton>
              <ActionButton
                style={{ marginTop: '16px' }}
                fullWidth
                buttonVariant="outlined"
                onClick={() => onCTA('contact_cs')}
              >
                {t('contactCs')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    // INVALID_TOKEN_ID_ERROR && AUTHENTICATION_ID_MISSING_ERROR && INVALID_AUTHENTICATION_ID_ERROR
    // TOKEN_ALREADY_USED_ERROR && TOKEN_NOT_FOUND_ERROR && AUTHENTICATION_NOT_FOUND_ERROR && MID_NOT_FOUND_ERROR
    // CARDHOLDER_NAME_REQUIRED && API_VALIDATION_ERROR CHECKOUT
    case 'MID_NOT_FOUND_ERROR':
      return (
        <Box>
          <StyledStateImage type="system-error" />
          <ErrorLabel>{t('key_payment_failed')}</ErrorLabel>
          <ErrorDesc>
            {t('key_payment_failed_after_checkout_desc')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('change_card')}
              >
                {t('key_try_with_different_card')}
              </ActionButton>
              <ActionButton
                style={{ marginTop: '16px' }}
                fullWidth
                buttonVariant="outlined"
                onClick={() => onCTA('contact_cs')}
              >
                {t('contactCs')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )

    case 'VERIFICATION_TIMEOUT_ERROR':
      return (
        <Box>
          <StyledStateImage type="pending-withdraw" />
          <ErrorLabel>{t('key_payment_time_expired')}</ErrorLabel>
          <ErrorDesc>{t('key_payment_time_expired_desc')}</ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('re_book')}
              >
                {t('key_reorder_btn')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )

    case 'TEMPORARY_SERVICE_ERROR':
      return (
        <Box>
          <StyledStateImage type="card-failed" />
          <ErrorLabel>
            {t(
              'key_temporary_problems_occurred_in_contacting_the_bank',
            )}
          </ErrorLabel>
          <ErrorDesc>
            {t(
              'key_temporary_problems_occurred_in_contacting_the_bank_desc',
            )}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton fullWidth onClick={() => onCTA('retry')}>
                {t('retry')}
              </ActionButton>
              <ActionButton
                style={{ marginTop: '16px' }}
                fullWidth
                buttonVariant="outlined"
                onClick={() => onCTA('contact_cs')}
              >
                {t('contactCs')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )

    case 'ACCOUNT_NUMBER_INVALID_ERROR':
      return (
        <Box>
          <StyledStateImage type="card-failed" />
          <ErrorLabel>{t('key_invalid_card_number')}</ErrorLabel>
          <ErrorDesc>{t('key_invalid_card_number_desc')}</ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('check_card_detail')}
              >
                {t('key_check_card_details')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'ISSUER_UNAVAILABLE':
      return (
        <Box>
          <StyledStateImage type="card-bank" />
          <ErrorLabel>
            {t('key_card_issuer_cannot_be_used_yet')}
          </ErrorLabel>
          <ErrorDesc>
            {t(
              'key_card_issuer_cannot_be_used_yet_after_checkout_desc',
            )}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('change_card')}
              >
                {t('key_try_with_different_card')}
              </ActionButton>
              <ActionButton
                style={{ marginTop: '16px' }}
                fullWidth
                buttonVariant="outlined"
                onClick={() => onCTA('trx_history')}
              >
                {getIdToken()
                  ? t('key_check_transaction_history')
                  : t('key_back_to_event_details')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    //DECLINED_BY_PROCESSOR && DECLINED_BY_ISSUER
    case 'DECLINED_BY_ISSUER':
      return (
        <Box>
          <StyledStateImage type="card-failed" />
          <ErrorLabel>
            {t('key_card_rejected_by_bank_declined')}
          </ErrorLabel>
          <ErrorDesc>
            {t(
              'key_card_rejected_by_bank_declined_after_checkout_desc',
            )}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('change_card')}
              >
                {t('key_try_with_different_card')}
              </ActionButton>
              <ActionButton
                style={{ marginTop: '16px' }}
                fullWidth
                buttonVariant="outlined"
                onClick={() => onCTA('trx_history')}
              >
                {getIdToken()
                  ? t('key_check_transaction_history')
                  : t('key_back_to_event_details')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'EXPIRED_CARD':
      return (
        <Box>
          <StyledStateImage type="card-failed" />
          <ErrorLabel>{t('key_card_has_expired')}</ErrorLabel>
          <ErrorDesc>
            {t('key_card_has_expired_after_checkout_desc')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('change_card')}
              >
                {t('key_try_with_different_card')}
              </ActionButton>
              <ActionButton
                style={{ marginTop: '16px' }}
                fullWidth
                buttonVariant="outlined"
                onClick={() => onCTA('trx_history')}
              >
                {getIdToken()
                  ? t('key_check_transaction_history')
                  : t('key_back_to_event_details')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'ISSUER_SUSPECT_FRAUD':
      return (
        <Box>
          <StyledStateImage type="card-failed" />
          <ErrorLabel>
            {t('key_card_rejected_by_bank_declined')}
          </ErrorLabel>
          <ErrorDesc>
            {t(
              'key_card_rejected_by_bank_or_fraud_after_checkout_desc',
            )}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('change_card')}
              >
                {t('key_try_with_different_card')}
              </ActionButton>
              <ActionButton
                style={{ marginTop: '16px' }}
                fullWidth
                buttonVariant="outlined"
                onClick={() => onCTA('trx_history')}
              >
                {getIdToken()
                  ? t('key_check_transaction_history')
                  : t('key_back_to_event_details')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'INACTIVE_OR_UNAUTHORIZED_CARD':
      return (
        <Box>
          <StyledStateImage type="card-failed" />
          <ErrorLabel>
            {t('key_card_rejected_by_bank_declined')}
          </ErrorLabel>
          <ErrorDesc>
            {t(
              'key_card_rejected_by_bank_declined_after_checkout_desc',
            )}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('change_card')}
              >
                {t('key_try_with_different_card')}
              </ActionButton>
              <ActionButton
                style={{ marginTop: '16px' }}
                fullWidth
                buttonVariant="outlined"
                onClick={() => onCTA('trx_history')}
              >
                {getIdToken()
                  ? t('key_check_transaction_history')
                  : t('key_back_to_event_details')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )

    case 'INSUFFICIENT_BALANCE':
      return (
        <Box>
          <StyledStateImage type="card-balance" />
          <ErrorLabel>{t('key_insufficient_card_limit')}</ErrorLabel>
          <ErrorDesc>
            {t('key_insufficient_balance_after_checkout_desc')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('change_card')}
              >
                {t('key_try_with_different_card')}
              </ActionButton>
              <ActionButton
                style={{ marginTop: '16px' }}
                fullWidth
                buttonVariant="outlined"
                onClick={() => onCTA('trx_history')}
              >
                {getIdToken()
                  ? t('key_check_transaction_history')
                  : t('key_back_to_event_details')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )

    case 'INVALID_CARD':
      return (
        <Box>
          <StyledStateImage type="card-failed" />
          <ErrorLabel>{t('key_invalid_card')}</ErrorLabel>
          <ErrorDesc>
            {t('key_invalid_card_after_checkout_desc')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('check_card_detail')}
              >
                {t('key_check_card_details')}
              </ActionButton>
              <ActionButton
                style={{ marginTop: '16px' }}
                fullWidth
                buttonVariant="outlined"
                onClick={() => onCTA('trx_history')}
              >
                {getIdToken()
                  ? t('key_check_transaction_history')
                  : t('key_back_to_event_details')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )

    case 'INVALID_CVV':
      return (
        <Box>
          <StyledStateImage type="card-failed" />
          <ErrorLabel>{t('key_invalid_CVN_CVV')}</ErrorLabel>
          <ErrorDesc>
            {t('key_invalid_CVN_CVV_after_checkout_desc')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('check_card_detail')}
              >
                {t('key_check_card_code')}
              </ActionButton>
              <ActionButton
                style={{ marginTop: '16px' }}
                fullWidth
                buttonVariant="outlined"
                onClick={() => onCTA('trx_history')}
              >
                {getIdToken()
                  ? t('key_check_transaction_history')
                  : t('key_back_to_event_details')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )

    case 'STOLEN_CARD':
      return (
        <Box>
          <StyledStateImage type="card-failed" />
          <ErrorLabel>
            {t('key_card_rejected_by_bank_declined')}
          </ErrorLabel>
          <ErrorDesc>
            {t(
              'key_card_rejected_by_bank_or_stolen_after_checkout_desc',
            )}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('change_card')}
              >
                {t('key_try_with_different_card')}
              </ActionButton>
              <ActionButton
                style={{ marginTop: '16px' }}
                fullWidth
                buttonVariant="outlined"
                onClick={() => onCTA('trx_history')}
              >
                {getIdToken()
                  ? t('key_check_transaction_history')
                  : t('key_back_to_event_details')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )

    case 'PROCESSOR_TIMEOUT':
      return (
        <Box>
          <Box
            style={{
              marginBottom: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '240px',
              height: '180px',
            }}
          >
            <IllustrationNetworkError />
          </Box>
          <ErrorLabel>{t('key_no_connection')}</ErrorLabel>
          <ErrorDesc>{t('key_no_connection_desc')}</ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('key_no_connection')}
              >
                {t('retry')}
              </ActionButton>
              <ActionButton
                style={{ marginTop: '16px' }}
                fullWidth
                buttonVariant="outlined"
                onClick={() => onCTA('trx_history')}
              >
                {getIdToken()
                  ? t('key_check_transaction_history')
                  : t('key_back_to_event_details')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )

    case 'CONNECTION_ERROR':
      return (
        <Box>
          <Box
            style={{
              marginBottom: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '240px',
              height: '180px',
            }}
          >
            <IllustrationNetworkError />
          </Box>
          <ErrorLabel>{t('key_no_connection')}</ErrorLabel>
          <ErrorDesc>{t('key_no_connection_desc')}</ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('refresh')}
              >
                {t('refresh')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )

    case 'FRAUD_RISK_BLOCKED':
      return (
        <Box>
          <StyledStateImage type="card-failed" />
          <ErrorLabel>
            {t('key_card_rejected_by_bank_declined')}
          </ErrorLabel>
          <ErrorDesc>
            {t(
              'key_card_rejected_by_bank_or_fraud_after_checkout_desc',
            )}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('change_card')}
              >
                {t('key_try_with_different_card')}
              </ActionButton>
              <ActionButton
                style={{ marginTop: '16px' }}
                fullWidth
                onClick={() => onCTA('trx_history')}
                buttonVariant="outlined"
              >
                {getIdToken()
                  ? t('key_check_transaction_history')
                  : t('key_back_to_event_details')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )

    case 'INVALID_CVN_LENGTH_ERROR':
      return (
        <Box>
          <StyledStateImage type="card-failed" />
          <ErrorLabel>{t('key_invalid_CVN_CVV')}</ErrorLabel>
          <ErrorDesc>
            {t('key_invalid_CVN_CVV_after_checkout_desc')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('check_card_detail')}
              >
                {t('key_check_card_code')}
              </ActionButton>
              <ActionButton
                style={{ marginTop: '16px' }}
                fullWidth
                onClick={() => onCTA('trx_history')}
                buttonVariant="outlined"
              >
                {getIdToken()
                  ? t('key_check_transaction_history')
                  : t('key_back_to_event_details')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )

    case 'AMOUNT_GREATER_THAN_AUTHENTICATED_ERROR':
      return (
        <Box>
          <StyledStateImage type="card-balance" />
          <ErrorLabel>
            {t('key_transaction_nominal_exceeds_limit')}
          </ErrorLabel>
          <ErrorDesc>
            {t(
              'key_transaction_nominal_exceeds_limit_after_checkout_desc',
            )}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('change_card')}
              >
                {t('key_try_with_different_card')}
              </ActionButton>
              <ActionButton
                style={{ marginTop: '16px' }}
                fullWidth
                onClick={() => onCTA('trx_history')}
                buttonVariant="outlined"
              >
                {getIdToken()
                  ? t('key_check_transaction_history')
                  : t('key_back_to_event_details')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )

    case 'AMOUNT_BELOW_MINIMUM_LIMIT':
      return (
        <Box>
          <StyledStateImage type="card-balance" />
          <ErrorLabel>
            {t('key_transaction_amount_insufficient')}
          </ErrorLabel>
          <ErrorDesc>
            {t(
              'key_transaction_amount_insufficient_after_checkout_desc',
            )}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('change_card')}
              >
                {t('key_check_transaction_amount')}
              </ActionButton>
              <ActionButton
                style={{ marginTop: '16px' }}
                fullWidth
                onClick={() => onCTA('trx_history')}
                buttonVariant="outlined"
              >
                {getIdToken()
                  ? t('key_check_transaction_history')
                  : t('key_back_to_event_details')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )

    case 'AMOUNT_ABOVE_MAXIMUM_LIMIT':
      return (
        <Box>
          <StyledStateImage type="card-balance" />
          <ErrorLabel>
            {t('key_transaction_nominal_exceeds_limit')}
          </ErrorLabel>
          <ErrorDesc>
            {t(
              'key_transaction_nominal_exceeds_limit_after_checkout_desc',
            )}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('change_card')}
              >
                {t('key_try_with_different_card')}
              </ActionButton>
              <ActionButton
                style={{ marginTop: '16px' }}
                fullWidth
                onClick={() => onCTA('trx_history')}
                buttonVariant="outlined"
              >
                {getIdToken()
                  ? t('key_check_transaction_history')
                  : t('key_back_to_event_details')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )

    case 'INCOMPLETE_AUTHENTICATION':
      return (
        <Box>
          <StyledStateImage type="card-failed" />
          <ErrorLabel>{t('key_authentication_failed')}</ErrorLabel>
          <ErrorDesc>
            {t('key_authentication_failed_after_checkout_desc')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('check_card_detail')}
              >
                {t('key_check_card_details')}
              </ActionButton>
              <ActionButton
                style={{ marginTop: '16px' }}
                fullWidth
                onClick={() => onCTA('trx_history')}
                buttonVariant="outlined"
              >
                {getIdToken()
                  ? t('key_check_transaction_history')
                  : t('key_back_to_event_details')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )

    case 'GENERAL_ERROR':
      return (
        <Box>
          <StyledStateImage type="system-error" />
          <ErrorLabel>{t('key_authentication_failed')}</ErrorLabel>
          <ErrorDesc>
            {t('key_authentication_failed_after_checkout_desc')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('check_card_detail')}
              >
                {t('key_check_card_details')}
              </ActionButton>
              <ActionButton
                style={{ marginTop: '16px' }}
                fullWidth
                onClick={() => onCTA('trx_history')}
                buttonVariant="outlined"
              >
                {getIdToken()
                  ? t('key_check_transaction_history')
                  : t('key_back_to_event_details')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'AUTHENTICATION_ALREADY_USED_ERROR':
      return (
        <Box>
          <StyledStateImage type="system-error" />
          <ErrorLabel>
            {t('vaPaymentInstructionWidgetErrorTitle')}
          </ErrorLabel>
          <ErrorDesc>
            {t('key_payment_general_error_after_checkout_message')}
          </ErrorDesc>
          {countDownToRetry !== 0 && (
            <ErrorCountdown>
              {' '}
              Dalam waktu {countDownToRetry}
            </ErrorCountdown>
          )}
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                disabled={disabled || countDownToRetry !== 0}
                onClick={() => {
                  handleCountdownClick()
                }}
              >
                {t('retry')}
              </ActionButton>

              <ActionButton
                style={{ marginTop: '16px' }}
                fullWidth
                buttonVariant="outlined"
                disabled={disabled || countDownToRetry !== 0}
                onClick={() => onCTA('trx_history')}
              >
                {getIdToken()
                  ? t('key_check_transaction_history')
                  : t('key_back_to_event_details')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'FAILED_CANCEL':
      return (
        <Box>
          <StyledStateImage type="user-alert" />
          <ErrorLabel>{t('key_order_cannot_cancelled')}</ErrorLabel>
          <ErrorDesc>
            {t('key_order_cannot_cancelled_desc')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                style={{ marginTop: '16px' }}
                fullWidth
                onClick={() => onCTA('contact_cs')}
              >
                {t('contactCs')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'FAILED_CONTINUE':
      return (
        <Box>
          <StyledStateImage type="error" />
          <ErrorLabel>{t('key_purchase_failed')}</ErrorLabel>
          <ErrorDesc>
            {t('key_check_status_payment_failed')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                style={{ marginTop: '16px' }}
                fullWidth
                onClick={() => onCTA()}
              >
                {t('key_repeat_transaction')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'REDIRECT_CONFIRMATION':
      return (
        <Box>
          <StyledStateImage type="share-content" />
          <ErrorLabel>{t('key_payment_not_completed')}</ErrorLabel>
          <ErrorDesc>{t('key_complete_your_payment_now')}</ErrorDesc>
          {showCTA && (
            <Box style={{ display: 'flex', gap: 16 }}>
              <ActionButton
                fullWidth
                disabled={disabled || countDownToRetry !== 0}
                buttonVariant="outlined"
                onClick={() => onCTA('back')}
              >
                {t('key_back_to_event_details')}
              </ActionButton>

              <ActionButton
                fullWidth
                disabled={disabled || countDownToRetry !== 0}
                onClick={() => onCTA('continue')}
              >
                {t('key_continue_payment')}
              </ActionButton>
            </Box>
          )}
        </Box>
      )
    case 'GENERAL_ERROR_PAYMENT':
      return (
        <Box>
          <StyledStateImage type="system-error" />
          <ErrorLabel>{t('otherApiErrorTitle')}</ErrorLabel>
          <ErrorDesc>
            {t('vaPaymentInstructionWidgetErrorDesc')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton fullWidth onClick={() => onCTA('close')}>
                {t('retry')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'EXIT_TICKET_LIST':
      return (
        <Box>
          <StateImage
            sx={{
              marginBottom: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            type="state_features"
          />
          <ErrorLabel>{t('key_leave_page')}</ErrorLabel>
          <ErrorDesc>{t('key_leave_page_desc')}</ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              {
                <ActionButton
                  id="b-create-new-booking-error-modal"
                  disabled={disabled}
                  fullWidth
                  buttonVariant="outlined"
                  onClick={() => onCTA('close')}
                >
                  {t('cancel')}
                </ActionButton>
              }
              <ActionButton
                id="b-finish-booking-error-modal"
                disabled={disabled}
                fullWidth
                onClick={() => onCTA('exit')}
              >
                {t('key_exit')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'BOOKING_LIST':
      return (
        <Box>
          <StateImage
            sx={{
              marginBottom: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            type="error"
          />
          <ErrorLabel>{t('Gagal Memuat Pembelian')}</ErrorLabel>
          <ErrorDesc>
            {t('Gagal memuat halaman pembelian. Silakan coba lagi')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              {
                <ActionButton
                  id="b-create-new-booking-error-modal"
                  disabled={disabled}
                  onClick={() => onCTA('refresh')}
                  sx={{ px: '24px' }}
                >
                  {t('tryAgain')}
                </ActionButton>
              }
            </ContainerBtn>
          )}
        </Box>
      )

    case 'BOOKING_DELIVERY':
      return (
        <Box>
          <StateImage
            sx={{
              marginBottom: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            type="error"
          />
          <ErrorLabel>{t('Gagal Menampilkan Tiket')}</ErrorLabel>
          <ErrorDesc>
            {t(
              'Maaf, sistem gagal menampilkan tiket event. Silakan refresh untuk memuat ulang tiket.',
            )}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              {
                <ActionButton
                  id="b-create-new-booking-error-modal"
                  disabled={disabled}
                  fullWidth
                  buttonVariant="nude"
                  buttonType="primary"
                  onClick={() => onCTA('refresh')}
                  sx={(theme) => ({
                    border: `1px solid ${theme.palette.primary.main}`,
                  })}
                >
                  {t('refresh')}
                </ActionButton>
              }
            </ContainerBtn>
          )}
        </Box>
      )
    case 'TOO_MANY_REQUEST':
      return (
        <Box>
          <StateImage
            sx={{
              marginBottom: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            type="error"
          />
          <ErrorLabel>
            {t('key_otp_request_limit_device_title')}
          </ErrorLabel>
          <ErrorDesc>
            {t('key_otp_request_limit', {
              verificationLimit: data.attempts,
              remainingTime:
                data.seconds > 60
                  ? `${Math.ceil(data.seconds / 60)} ${t('minutes')}`
                  : `${data.seconds} ${t('seconds')} `,
            })}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              {
                <ActionButton
                  disabled={disabled}
                  fullWidth
                  buttonVariant="outlined"
                  buttonType="primary"
                  onClick={onCTA}
                  sx={(theme) => ({
                    border: `1px solid ${theme.palette.primary.main}`,
                  })}
                >
                  {t('backToTiptip')}
                </ActionButton>
              }
            </ContainerBtn>
          )}
        </Box>
      )
    case 'LOAD_FAIL': {
      return (
        <Attention
          padding="20px"
          title={t('key_failed_load_purchase_title')}
          desc={t('key_failed_load_purchase_desc')}
          textBtnFirst={t('tryAgain')}
          variantBtnFirst="filled"
          handleRedirectBtnFirst={onCTA}
        >
          <StateImage type="error" />
        </Attention>
      )
    }
    default:
      return (
        <Box>
          <StyledStateImage type={''} />
          <ErrorLabel>{t('otherApiErrorTitle')}</ErrorLabel>
          <ErrorDesc>
            {t('vaPaymentInstructionWidgetErrorDesc')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton fullWidth onClick={() => onCTA('close')}>
                {t('close')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
  }
}

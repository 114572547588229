import { StackProps, SvgIcon } from '@mui/material'

import { Caption, Container } from './styled'
import { getStateFromSource, trackEvent } from 'helpers/analytics'
import redirect from 'helpers/redirect'
import { useRouter } from 'next/router'
import ChipNewFeature from 'components/commons/ChipNewFeature'
import CommonStack from 'components/commons/Stack'
import isEmpty from 'helpers/is-empty'

import { useMultipleLanguageStore } from 'stores/commons'
import Stack from 'components/commons/Stack'
import Typography from 'components/commons/Typography'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import countryLang from 'constants/language'
import { getLocalStorage } from 'helpers/local-storage'
import appConfig from 'configs/app'
import Flag from 'react-country-flag'

export interface MenuItemProps extends StackProps {
  title: string
  icon: typeof SvgIcon
  iconColor?: string
  eventName: string
  url: string
  isNewTab?: boolean
  isNewFeature?: boolean
  id?: string
  isMobileOnly?: boolean
  dark?: boolean
  additionalProperties?: object
  isBottomDrawer?: boolean
  isRightSection?: boolean
  isGuest?: boolean
  hidden?: boolean
}

export function MenuItem({
  title,
  isNewFeature = false,
  isNewTab = false,
  isMobileOnly = false,
  icon: Icon,
  iconColor = '',
  url,
  eventName,
  additionalProperties = {},
  isBottomDrawer,
  isRightSection,
  hidden = false,
  ...rest
}: MenuItemProps) {
  const { pathname, isReady } = useRouter()
  const { t } = useTranslation()

  const {
    setShowMultipleLanguageDrawer,
    currentLanguage,
    setLanguage,
  } = useMultipleLanguageStore((state) => ({
    setShowMultipleLanguageDrawer:
      state.setShowMultipleLanguageDrawer,
    currentLanguage: state.currentLanguage,
    setLanguage: state.setLanguage,
  }))
  const visitURL = () => {
    if (isNewTab) {
      window.open(url, '_blank')
    } else {
      redirect(`${url}?source=${pathname}&source_action=${eventName}`)
    }
  }

  const handleClickMenu = () => {
    if (eventName) {
      const eventProperties = {
        state_name: getStateFromSource(pathname),
        modal_name: 'USER_NAVIGATION_MENU',
        ...additionalProperties,
      }
      trackEvent(eventName, eventProperties)
    }
    if (!isEmpty(url)) visitURL()
  }

  const CountrySection = () => {
    return (
      <Stack
        direction={'row'}
        sx={{
          justifyContent: 'space-around',
          textAlign: 'center',
          gap: 1,
          img: {
            alignSelf: `center`,
          },
        }}
      >
        <Flag
          countryCode={currentLanguage?.flag}
          svg
          style={{
            width: '16px',
          }}
          alt={currentLanguage.flag}
        />
        <Typography>{currentLanguage.label}</Typography>
      </Stack>
    )
  }

  useEffect(() => {
    if (isReady) {
      const lng =
        getLocalStorage('i18nextLng') || appConfig.defaultLang

      const selectedLanguage = countryLang.find(
        (language) => language.id === lng,
      )

      setLanguage(selectedLanguage)
    }
  }, [isReady])

  if (hidden) {
    return <></>
  }

  return (
    <Container
      flex={1}
      sx={{
        columnGap: 1.5,
        display: isMobileOnly ? { xs: 'flex', md: 'none' } : 'flex',
      }}
      onClick={() => {
        !isBottomDrawer
          ? handleClickMenu()
          : setShowMultipleLanguageDrawer(true)
      }}
      {...rest}
    >
      <CommonStack
        justifyContent="center"
        alignItems="center"
        width="24px"
      >
        <Icon
          color="tertiery"
          sx={{
            width: 18,
            height: 18,
            color: iconColor && `${iconColor} !important`,
          }}
        />
      </CommonStack>
      <CommonStack
        flex={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Caption main>{t(title)}</Caption>
        {isNewFeature && <ChipNewFeature>NEW</ChipNewFeature>}
        {isRightSection && <CountrySection />}
      </CommonStack>
    </Container>
  )
}

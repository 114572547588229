export const authErrorConst = {
  USER_REQUEST_OTP_REACH_MAXIMUM: 'USER_REQUEST_OTP_REACH_MAXIMUM',
  FRAUD_USER_PHONE_NUMBER_DETECTED:
    'FRAUD_USER_PHONE_NUMBER_DETECTED',
  USER_VERIFY_OTP_REACH_MAXIMUM: 'USER_VERIFY_OTP_REACH_MAXIMUM',
  INVALID_PHONE_NUMBER_FORMAT: 'INVALID_PHONE_NUMBER_FORMAT',
  TOO_MANY_REQUESTS: 'TOO_MANY_REQUESTS',
  USER_IS_SUSPENDED: 'USER_IS_SUSPENDED',
  BAD_REQUEST: 'BAD_REQUEST',
  INVALID_CODE: 'INVALID_CODE',
  EXPIRED_CODE: 'EXPIRED_CODE',
  PHONE_NUMBER_ALREADY_USED: 'PHONE_NUMBER_ALREADY_USED',
  EMAIL_ALREADY_EXIST: 'EMAIL_ALREADY_EXIST',
  FOUND_IN_APPLE_ACCOUNT: 'FOUND_IN_APPLE_ACCOUNT',
}

import { ReactNode } from 'react'
import {
  StyledContainer,
  StyledStack,
  CoinInfoContainer,
  CoinIconContainer,
  CoinQuantityLabel,
  CoinPriceContainer,
  CoinPriceLabel,
  PriceCurrency,
  StyledDivider,
  TotalPaymentLabel,
  PromoDesc,
  StyledCheck,
  StyledTags,
  StyledArrow,
  StyledContainerVoucher,
  StyledDesc,
  StyledArrowRightContainer,
  CoinLabelDiscount,
  CoinValueLabel,
  StyledIconButton,
  StyledTitleDetailPayment,
  StyledNote,
  StyledCoin,
} from 'components/domains/Wallet/DirectPaymentDrawerContent/styled'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import { useMediaQuery, useTheme } from '@mui/material'

import NumberBaseFormatter from 'helpers/formats/number-base-format'
import Box from 'components/commons/Box'
import Typography from 'components/commons/Typography'
import { StyledHelpIcon } from 'containers/domains/Wallet/DirectPaymentModal/styled'
import { StyledInfoIcon } from 'containers/domains/Wallet/PaymentInstruction/styled'
import { useTranslation } from 'react-i18next'

interface IDirectPaymentItem {
  label: string | ReactNode
  price: number
}

interface IDirectPaymentDrawerContent {
  items: IDirectPaymentItem[]
  fees: IDirectPaymentItem[]
  totalPrice: number
  totalPriceCoin?: number
  subTotalPrice: number
  useVoucher?: boolean
  isEligibleVoucher?: string
  voucherCode?: any
  handleVoucherClear?: Function
  voucherInboxOpen?: Function
  itemType: string
  isCoinMethod?: boolean
  totalCoinDisplay?: boolean
  setShowCoinInfo?: Function
  setIsAdditionalFeesOpen?: Function
  isPaymentLoading?: boolean
}

export default function DirectPaymentDrawerContent({
  items,
  fees,
  totalPrice,
  totalPriceCoin,
  subTotalPrice,
  useVoucher = true,
  isEligibleVoucher = '',
  voucherCode,
  handleVoucherClear,
  voucherInboxOpen,
  itemType,
  isCoinMethod,
  totalCoinDisplay,
  setShowCoinInfo,
  setIsAdditionalFeesOpen,
  isPaymentLoading,
}: IDirectPaymentDrawerContent) {
  const { t } = useTranslation()
  const theme = useTheme()
  const isDesktopResolution = useMediaQuery(
    theme.breakpoints.up('sm'),
  )
  const isAdditionalFeeMethod = (itemIndex) => {
    if (itemIndex === 1) {
      if (itemType === 'event' || itemType === 'content') {
        return true
      } else {
        return false
      }
    } else if (itemIndex === 2) {
      return true
    }
  }

  const feeIdHandler = (itemIndex) => {
    if (itemIndex === 1) {
      if (itemType === 'event' || itemType === 'content') {
        return 'voucher'
      } else {
        return 'tax'
      }
    } else if (itemIndex === 2) {
      return 'voucher'
    }
  }

  const TotalPaymentContent = () => {
    if (!isCoinMethod && !totalCoinDisplay) {
      return (
        <CoinPriceContainer
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
          style={{ display: isDesktopResolution ? 'flex' : 'block' }}
        >
          {voucherCode ? (
            <>
              <CoinLabelDiscount>
                Rp
                {NumberBaseFormatter(subTotalPrice)}
              </CoinLabelDiscount>
              <CoinValueLabel
                id={`c-wallet-${itemType}-total-price-value`}
              >
                <PriceCurrency>Rp</PriceCurrency>
                {NumberBaseFormatter(totalPrice)}
              </CoinValueLabel>
            </>
          ) : (
            <CoinValueLabel
              id={`c-wallet-${itemType}-total-price-value`}
            >
              <PriceCurrency>Rp</PriceCurrency>
              {NumberBaseFormatter(totalPrice)}
            </CoinValueLabel>
          )}
        </CoinPriceContainer>
      )
    } else {
      return (
        <CoinPriceContainer
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
          style={{ display: isDesktopResolution ? 'flex' : 'block' }}
        >
          {voucherCode ? (
            <>
              <CoinLabelDiscount>
                <StyledCoin />
                {NumberBaseFormatter(subTotalPrice)}
              </CoinLabelDiscount>
              <CoinValueLabel
                id={`c-wallet-${itemType}-total-price-value`}
              >
                <PriceCurrency>
                  <StyledCoin />
                </PriceCurrency>
                {NumberBaseFormatter(totalPrice)}
              </CoinValueLabel>
            </>
          ) : (
            <Box style={{ display: 'block' }}>
              <CoinValueLabel
                id={`c-wallet-${itemType}-total-price-value`}
              >
                <PriceCurrency>Rp</PriceCurrency>
                {NumberBaseFormatter(totalPrice)}
              </CoinValueLabel>
              {(itemType === 'event' || itemType === 'content') && (
                <CoinValueLabel>
                  (
                  <PriceCurrency>
                    <StyledCoin />
                  </PriceCurrency>
                  {NumberBaseFormatter(totalPriceCoin)})
                </CoinValueLabel>
              )}
            </Box>
          )}
          {setShowCoinInfo && (
            <StyledInfoIcon
              style={{ marginLeft: '3px', marginTop: '-1px' }}
              onClick={() => setShowCoinInfo(true)}
            />
          )}
        </CoinPriceContainer>
      )
    }
  }

  return (
    <StyledContainer>
      {itemType === 'session' && (
        <StyledNote>
          <InfoIcon
            sx={{
              color: theme.palette.info.main,
              fontSize: '18px',
              marginRight: '6px',
            }}
          />
          {t('key_complete_the_payment_immidiate')}
        </StyledNote>
      )}
      {itemType !== 'session' && (
        <StyledTitleDetailPayment>
          {t('paymentConfirmationBottomSheetPaymentSummary')}
        </StyledTitleDetailPayment>
      )}
      <StyledStack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        {
          <>
            {items.map((item, itemIndex) => {
              return (
                <CoinInfoContainer
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={1}
                  key={itemIndex}
                >
                  <CoinIconContainer
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    <CoinQuantityLabel>
                      {item.label}
                    </CoinQuantityLabel>
                  </CoinIconContainer>
                  <CoinPriceContainer
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                  >
                    <CoinPriceLabel
                      id={`c-wallet-${itemType}-price-value`}
                    >
                      <PriceCurrency>Rp</PriceCurrency>
                      {NumberBaseFormatter(item.price)}
                    </CoinPriceLabel>
                  </CoinPriceContainer>
                </CoinInfoContainer>
              )
            })}
            {fees.map((item, itemIndex) => {
              if (item.label !== '') {
                return (
                  <CoinInfoContainer
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={1}
                    key={itemIndex}
                  >
                    <CoinIconContainer
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                    >
                      <CoinQuantityLabel
                        styleColor={isAdditionalFeeMethod(itemIndex)}
                      >
                        {item.label}
                      </CoinQuantityLabel>
                      {typeof item.label === 'string' &&
                        t(item.label) ===
                          t(
                            'paymentDetailWidgetAdditionalFeeLabel',
                          ) &&
                        (itemType === 'event' ||
                          itemType === 'content') && (
                          <StyledHelpIcon
                            onClick={() => {
                              setIsAdditionalFeesOpen(true)
                            }}
                          />
                        )}
                    </CoinIconContainer>
                    <CoinPriceContainer
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={1}
                    >
                      <CoinPriceLabel
                        id={`c-wallet-${itemType}-${feeIdHandler(
                          itemIndex,
                        )}-value`}
                        styleColor={isAdditionalFeeMethod(itemIndex)}
                      >
                        <PriceCurrency
                          styleColor={isAdditionalFeeMethod(
                            itemIndex,
                          )}
                        >
                          {isAdditionalFeeMethod(itemIndex)
                            ? '-'
                            : ''}
                          Rp
                        </PriceCurrency>
                        {NumberBaseFormatter(item.price)}
                      </CoinPriceLabel>
                    </CoinPriceContainer>
                  </CoinInfoContainer>
                )
              }
            })}
            <StyledDivider />
          </>
        }
        <CoinInfoContainer
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={1}
        >
          <CoinIconContainer
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <TotalPaymentLabel>
              {t('paymentConfirmationBottomSheetTotalV2')}
            </TotalPaymentLabel>
          </CoinIconContainer>
          {TotalPaymentContent()}
        </CoinInfoContainer>
        {useVoucher && (
          <>
            <StyledDivider />
            <StyledContainerVoucher
              onClick={() => {
                isEligibleVoucher !== '' ||
                isPaymentLoading ||
                voucherCode
                  ? ''
                  : voucherInboxOpen()
              }}
              id="i-payment-promobox"
              border={voucherCode}
              disabled={isEligibleVoucher !== '' || isPaymentLoading}
              isError={isEligibleVoucher.length > 0}
            >
              <StyledDesc
                sx={{
                  [theme.breakpoints.up('sm')]: {
                    background: isEligibleVoucher
                      ? 'transparent'
                      : 'white',
                  },
                }}
              >
                {isEligibleVoucher ? (
                  ''
                ) : voucherCode ? (
                  <StyledCheck />
                ) : (
                  <StyledTags />
                )}
                <Typography
                  style={{
                    color: isPaymentLoading
                      ? theme.palette.text.tertiery
                      : theme.palette.text.primary,
                  }}
                >
                  {voucherCode
                    ? voucherCode.toUpperCase()
                    : t(
                        'paymentConfirmationBottomSheetVoucherDescText',
                      )}
                </Typography>
              </StyledDesc>
              {voucherCode ? (
                <StyledIconButton
                  disabled={isPaymentLoading}
                  onMouseDown={(event) => {
                    event.preventDefault()
                  }}
                  onClick={(event) => {
                    handleVoucherClear()
                    event.stopPropagation()
                  }}
                >
                  <HighlightOffIcon />
                </StyledIconButton>
              ) : (
                <StyledArrowRightContainer>
                  <StyledArrow
                    fontSize="small"
                    disabled={
                      isEligibleVoucher !== '' || isPaymentLoading
                    }
                  />
                </StyledArrowRightContainer>
              )}
            </StyledContainerVoucher>
            {isEligibleVoucher && (
              <CoinInfoContainer>
                <PromoDesc
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{ color: theme.palette.error.main }}
                  spacing={1}
                >
                  {isEligibleVoucher}
                </PromoDesc>
              </CoinInfoContainer>
            )}
          </>
        )}
      </StyledStack>
    </StyledContainer>
  )
}

/**
 * Returns error message for all auth
 *
 * @param code // Error code from API
 * @param additionalProperties
 */

import { t } from 'i18next'

export type ErrorCode =
  | 'USER_REQUEST_OTP_REACH_MAXIMUM'
  | 'FRAUD_USER_PHONE_NUMBER_DETECTED'
  | 'USER_VERIFY_OTP_REACH_MAXIMUM'
  | 'INVALID_PHONE_NUMBER_FORMAT'
  | 'TOO_MANY_REQUESTS'
  | 'USER_IS_SUSPENDED'
  | 'UNABLE_ADD_EMAIL'
  | 'SUGGEST_PHONE'
  | 'INCORRECT_USERNAME_OR_PASSWORD'
  | 'NOT_FOUND'
  | 'EMAIL_ALREADY_EXIST'
  | 'INVALID_CODE'
  | 'EXPIRED_CODE'
  | 'PHONE_NUMBER_ALREADY_USED'

interface IAdditionalProperties {
  seconds?: number
  attempts?: number
  defaultMessage?: string
  isEmail?: boolean
  isChangePassword?: boolean
  isForgotPassword?: boolean
  isEventVerification?: boolean
}

const renderErrorMessage = (
  code: ErrorCode,
  additionalProperties: IAdditionalProperties = {
    attempts: 0,
    seconds: 0,
    defaultMessage: '',
    isEmail: false,
    isChangePassword: false,
    isForgotPassword: false,
    isEventVerification: false,
  },
) => {
  const {
    seconds,
    attempts,
    defaultMessage,
    isEmail,
    isChangePassword,
    isForgotPassword,
    isEventVerification,
  } = additionalProperties
  let message = ''
  switch (code) {
    case 'USER_REQUEST_OTP_REACH_MAXIMUM':
    case 'USER_VERIFY_OTP_REACH_MAXIMUM':
      const counter = seconds > 60 ? Math.ceil(seconds / 60) : seconds
      message =
        seconds > 60
          ? t('maxTryOtpInMinutes', {
              retryInMinutes: counter,
            })
          : t('maxTryOtpInSecs', {
              retryInSecs: counter,
            })
      break
    case 'FRAUD_USER_PHONE_NUMBER_DETECTED':
      message = 'fraudPhoneDetectErrorMessage'
      break
    case 'INVALID_PHONE_NUMBER_FORMAT':
      message = 'inputValidPhoneNumber'
      break
    case 'TOO_MANY_REQUESTS':
      message = isEmail
        ? 'wrongPasswordMaxLimitWithOption'
        : isChangePassword
        ? 'wrongPasswordMaxLimit'
        : isForgotPassword
        ? t(
            'key_please_wait_duration_seconds_and_please_try_entering_your_email_again',
            {
              duration: seconds,
            },
          )
        : isEventVerification
        ? t('key_otp_request_limit', {
            attempts,
            retryInSecs: counter,
          })
        : t(
            'key_please_wait_duration_seconds_and_please_try_entering_your_cellphone_number_again',
            {
              duration: seconds,
            },
          )
      break
    case 'USER_IS_SUSPENDED':
      message = 'userSuspended'
      break
    case 'UNABLE_ADD_EMAIL':
      message = 'updateEmailDisabled'
      break
    case 'SUGGEST_PHONE':
      message = 'phoneNumberEmptyMessage'
      break
    case 'INCORRECT_USERNAME_OR_PASSWORD':
    case 'NOT_FOUND':
      message = isForgotPassword
        ? 'emailNotFound'
        : 'key_the_email_or_password_you_entered'
      break
    case 'EMAIL_ALREADY_EXIST':
      message = 'emailInUseErrorMessage'
      break
    case 'INVALID_CODE':
      message = 'otpInvalid'
      break
    case 'EXPIRED_CODE':
      message = 'otpCodeExpired'
      break
    case 'PHONE_NUMBER_ALREADY_USED':
      message =
        'key_the_cellphone_number_you_entered_is_already_registered'
      break
    default:
      message = defaultMessage || 'key_something_wrong_try_again'
      break
  }
  return message
}

export { renderErrorMessage }

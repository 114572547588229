import * as React from 'react'
import { NextRouter } from 'next/router'
import {
  setSessionStorage,
  getSessionStorage,
  destroySessionStorage,
} from 'helpers/session-storage'
import { skipCommonSessionURLs } from 'constants/no-back-redirect'
import { useLocationStore } from 'stores/domains/Social'

interface RoutingContextType {}

const RoutingContext = React.createContext<RoutingContextType>({})

interface RoutingProps {
  children: React.ReactNode
  router: NextRouter
}

const RoutingProvider = ({ children, router }: RoutingProps) => {
  const prevPath = getSessionStorage('prevPath')
  const currentPath = getSessionStorage('currentPath')

  const { resetSelectedLocation } = useLocationStore((state) => ({
    resetSelectedLocation: state.resetSelectedLocation,
  }))

  const storePathValues = () => {
    if (
      typeof globalThis === 'undefined' ||
      !globalThis?.sessionStorage ||
      !globalThis?.location?.pathname
    ) {
      return
    }

    const { pathname } = globalThis.location || {}

    if (prevPath === pathname && prevPath !== '/') {
      // Infinite loop detected, reset prevPath to home
      setSessionStorage('prevPath', '/')
      setSessionStorage('currentPath', pathname)
      setSessionStorage(
        'currentQuery',
        getSessionStorage('prevQuery'),
      )
      destroySessionStorage('prevQuery')
      return
    }

    const currentQuery = getSessionStorage('currentQuery')
    // skip for some common urls
    if (skipCommonSessionURLs.includes(currentPath)) {
      return
    }
    if (currentPath !== pathname) {
      setSessionStorage('prevPath', currentPath)
      setSessionStorage('currentPath', pathname)
    }

    setSessionStorage('prevQuery', currentQuery)
    setSessionStorage('currentQuery', router.query)
  }

  React.useEffect(() => {
    if (typeof globalThis === 'undefined' || !globalThis.location) {
      return
    }

    const { pathname } = globalThis.location

    // Condition: Reset state if prevPath is '/browse' and currentPath is '/'
    if (currentPath === '/' && prevPath === '/browse') {
      resetSelectedLocation()
    }

    // Reset all history when on the homepage ('/')
    if (pathname === '/') {
      setSessionStorage('prevPath', pathname)
      setSessionStorage('currentPath', pathname)
      destroySessionStorage('currentQuery')
      destroySessionStorage('prevQuery')
    }

    // Save path values when the router is ready
    if (router.isReady) {
      storePathValues()
    }
  }, [currentPath, prevPath, router.isReady, storePathValues])

  return (
    <RoutingContext.Provider value={{}}>
      {children}
    </RoutingContext.Provider>
  )
}

export { RoutingContext, RoutingProvider }

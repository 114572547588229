import { useQuery } from '@tanstack/react-query'
import get from 'lodash/get'
import { getProvinceListForHome } from 'services/recommendation'

import { getCityList, getfilterProvinceList } from 'services/social'
import { useLocationStore } from 'stores/domains/Social'

export function useFilterProvinceList(
  name?: string,
  page?: number,
  limit?: number,
) {
  const { setFilterProvinceList } = useLocationStore((state) => ({
    setFilterProvinceList: state.setFilterProvinceList,
  }))

  return useQuery({
    queryKey: ['province-list', name],
    queryFn: async () => {
      const response = await getfilterProvinceList({
        name,
        page,
        limit,
      })
      return get(response, 'data.data', [])
    },
    enabled: false,
    retry: false,
    onSuccess: (data) => {
      setFilterProvinceList(data)
    },
    refetchOnWindowFocus: false,
  })
}

export function useProvinceListHome() {
  const { setProvinceListHome } = useLocationStore((state) => ({
    setProvinceListHome: state.setProvinceListHome,
  }))
  return useQuery({
    queryKey: ['province-home'],
    queryFn: async () => {
      const response = await getProvinceListForHome()
      return get(response, 'data.data', [])
    },
    enabled: false,
    retry: false,
    onSuccess: (data) => {
      setProvinceListHome(data)
    },
    refetchOnWindowFocus: false,
  })
}

export function useCityList(page?: number, limit?: number) {
  const { setCityList } = useLocationStore((state) => ({
    setCityList: state.setCityList,
  }))
  return useQuery({
    queryKey: ['citi-list'],
    queryFn: async () => {
      const response = await getCityList({
        page,
        limit,
      })
      return get(response, 'data.data', [])
    },
    enabled: false,
    retry: false,
    onSuccess: (data) => {
      setCityList(data)
    },
    refetchOnWindowFocus: false,
  })
}

import _get from 'lodash/get'

const getErrorMessage = (error: Error) => {
  return _get(
    error,
    'response.data.message',
    error?.message || `Raw Error: ${JSON.stringify(error)}`,
  )
}

const getErrorCode = (error: Error) => {
  return _get(error, 'response.data.code', '')
}

export { getErrorCode, getErrorMessage }

import { useQuery } from '@tanstack/react-query'
import isEmpty from 'helpers/is-empty'
import get from 'lodash/get'

import { getCommunityDetail } from 'services/social'
import { useCommunityStore } from 'stores/domains/Social'

export const useCommunityDetail = (id: string) => {
  const { setCommunityDetail, setError } = useCommunityStore(
    (state) => ({
      setCommunityDetail: state.setCommunityDetail,
      setError: state.setError,
    }),
  )

  return useQuery({
    queryKey: ['community-detail', id],
    queryFn: async () => {
      try {
        if (isEmpty(id)) return Promise.reject()
        const response = await getCommunityDetail(id)
        return {
          data: get(response, 'data.data', {}),
          metadata: get(response, 'data._metadata', {}),
        }
      } catch (error) {
        throw error
      }
    },
    enabled: false,
    retry: false,
    onSuccess: (data) => setCommunityDetail(data),
    onError: (err) => setError(err),
    refetchOnWindowFocus: false,
  })
}

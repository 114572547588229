import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { ICommunityStore } from './Community/interface'
import { createCommunityStore } from './Community'
import { IFollowStore } from './Follow/interface'
import { createFollowStore } from './Follow'
import { IMissionCategoryStore } from './Mission/interface'
import { createMissionCategoryStore } from './Mission'
import { createMissionDetailStore } from './Mission/Detail'
import { IMissionDetailStore } from './Mission/Detail/interface'
import { ILocation } from './Location/interface'
import { createLocationSlice } from './Location'

export const useCommunityStore = create<ICommunityStore>()(
  persist(
    (...a) => ({
      ...createCommunityStore(...a),
    }),
    {
      name: 'community-store',
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) =>
              ![
                'setIsOpenScreeningQuestionDrawer',
                'setIsOpenPrivateCommunityContentModal',
                'setAlertOpen',
                'isOpenScreeningQuestionDrawer',
                'isOpenPrivateCommunityContentModal',
                'alertCommunity',
                'community',
                'selectedCommunityMainTab',
              ].includes(key),
          ),
        ),
      version: 1,
    },
  ),
)

export const useFollowStore = create<IFollowStore>()((...a) => ({
  ...createFollowStore(...a),
}))

export const useMissionCategoryStore =
  create<IMissionCategoryStore>()(
    persist(
      (...a) => ({
        ...createMissionCategoryStore(...a),
      }),
      {
        name: 'mission-category-store',
        partialize: (state) => ({
          isDialogClosed: state.isDialogClosed,
        }),
      },
    ),
  )

export const useMissionDetailStore = create<IMissionDetailStore>()(
  persist(
    (...a) => ({
      ...createMissionDetailStore(...a),
    }),
    {
      name: 'mission-detail-store',
      partialize: (state) => ({
        isActive: state.isActive,
        missionLink: state.missionLink,
      }),
    },
  ),
)

export const useLocationStore = create<ILocation>()(
  persist(
    (...a) => ({
      ...createLocationSlice(...a),
    }),
    {
      name: 'location-store',
    },
  ),
)

import {
  Avatar,
  Snackbar,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Box from 'components/commons/Box'
import Stack from 'components/commons/Stack'
import Typography from 'components/commons/Typography'

import BottomDrawer from 'components/commons/BottomDrawer/v2'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import {
  useCommunityStore,
  useFollowStore,
} from 'stores/domains/Social'
import { useState } from 'react'
import { postJoinLeaveCommunity } from 'services/social'
import imageResizer from 'helpers/image-resizer'
import { useCreatorFollowers } from 'hooks/domains/Creator'
import { useRouter } from 'next/router'
import { useContentCommunityInfo } from 'hooks/domains/Community'
import { CommunityImage } from 'components/domains/Community/CommunityImage'
import isEmpty from 'helpers/is-empty'
import MessageBox from 'components/commons/MessageBox'
import appConfig from 'configs/app'
import { useReferralStore } from 'stores/domains/User'
import redirect from 'helpers/redirect'
import ScreeningQuestionDrawer from '../ScreeningQuestionDrawer'
import ActionButton from 'components/commons/Button/Action'
import { useTranslation } from 'react-i18next'
import { postFollow } from 'services/user'

const StyledActionButton = styled(ActionButton)({
  width: '100%',
  borderRadius: '8px',
})

interface ISuggestFollowModalProps {
  name: string
  image: string
  type: 'community' | 'creator' | 'referral'
  communityId?: string
  username?: string
  customSubtitle?: string
  callback?: Function
}

const SuggestFollowModal = ({
  name,
  image,
  type,
  communityId,
  username,
  customSubtitle,
  callback,
}: ISuggestFollowModalProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const { pathname, query } = useRouter()
  const isEvent = pathname.includes('/event/')
  const [isLoading, setIsLoading] = useState(false)
  const [messageBox, setMessageBox] = useState('')
  const [isError, setIsError] = useState(false)

  const { refetch: refetchCreatorFollowers } =
    useCreatorFollowers(username)
  const { refetch: refetchContentCommunityInfo } =
    useContentCommunityInfo(
      isEvent ? String(query.event_uid) : String(query.content_uid),
    )
  const {
    isOpenSuggestFollowModal,
    setIsOpenSuggestFollowModal,
    setIsSuccessFollow,
    setSuccessMessage,
  } = useFollowStore((state) => ({
    isOpenSuggestFollowModal: state.isOpenSuggestFollowModal,
    setIsOpenSuggestFollowModal: state.setIsOpenSuggestFollowModal,
    setIsSuccessFollow: state.setIsSuccessFollow,
    setSuccessMessage: state.setSuccessMessage,
  }))
  const { referralCodeInfo, clearReferralCodeInfo } =
    useReferralStore((state) => ({
      referralCodeInfo: state.referralCodeInfo,
      clearReferralCodeInfo: state.clearReferralCodeInfo,
    }))
  const {
    isOpenScreeningQuestionDrawer,
    setIsOpenScreeningQuestionDrawer,
  } = useCommunityStore((state) => ({
    isOpenScreeningQuestionDrawer:
      state.isOpenScreeningQuestionDrawer,
    setIsOpenScreeningQuestionDrawer:
      state.setIsOpenScreeningQuestionDrawer,
  }))

  const handleClickJoinCommunityWithReferral = () => {
    if (
      type === 'referral' &&
      referralCodeInfo.community.is_private &&
      referralCodeInfo.community.has_screening_questions &&
      !isOpenScreeningQuestionDrawer
    ) {
      setIsOpenScreeningQuestionDrawer(true)
    } else {
      submitJoinCommunity()
    }
  }

  const submitJoinCommunity = async (additionalInfo = null) => {
    try {
      setMessageBox('')
      setIsLoading(true)
      const response = await postJoinLeaveCommunity(
        communityId,
        true,
        additionalInfo,
      )
      if (response?.data?.code === 'SUCCESS') {
        if (isOpenScreeningQuestionDrawer)
          setIsOpenScreeningQuestionDrawer(false)
        setIsOpenSuggestFollowModal(false)
        setIsSuccessFollow(true)
        if (type === 'referral') {
          if (referralCodeInfo.community.is_private)
            setMessageBox(
              'Hore, permintaan bergabung terkirim. Hasilnya akan kami sampaikan melalui notifikasi dan email',
            )
          else
            setMessageBox(
              `Yay, selamat bergabung di komunitas ${referralCodeInfo.community.name}!`,
            )
        } else {
          setSuccessMessage(`Berhasil bergabung komunitas "${name}"`)
          refetchContentCommunityInfo()
        }
        setTimeout(() => {
          if (callback) callback()
        }, 1500)
        setTimeout(() => {
          if (type === 'referral') clearReferralCodeInfo()
          setIsSuccessFollow(false)
          setSuccessMessage('')
        }, 3000)
      }
    } catch (error) {
      setIsError(true)
      setMessageBox('Gagal minta bergabung komunitas, coba lagi ya')
    } finally {
      setIsLoading(false)
    }
  }

  const submitFollowCreator = async () => {
    try {
      setMessageBox('')
      setIsLoading(true)
      const response = await postFollow({
        username: username,
        following: true,
      })
      const { code } = response
      if (code === 'SUCCESS') {
        setIsOpenSuggestFollowModal(false)
        setIsSuccessFollow(true)
        setSuccessMessage(`Berhasil follow creator "${name}"`)
        refetchCreatorFollowers()
        setTimeout(() => {
          setIsSuccessFollow(false)
          setSuccessMessage('')
        }, 3000)
      }
    } catch (error) {
      setMessageBox('Gagal follow creator, coba lagi ya')
    } finally {
      setIsLoading(false)
    }
  }

  const renderSubtitle = () => {
    if (customSubtitle) return customSubtitle
    if (type === 'creator')
      return t('creatorFollowSuggestionDesc', {
        name: name,
      })
    if (type === 'community' && isEvent)
      return `Event ini diselenggarakan oleh komunitas ${name}. Yuk, gabung untuk lebih mudah mengikuti event-event lainnya dan berinteraksi dengan anggota komunitas ini.`
    return `Konten digital ini dibuat oleh komunitas ${name}. Yuk, gabung untuk lebih mudah mendapatkan konten digital lainnya dan berinteraksi dengan anggota komunitas ini.`
  }

  const renderCommunityThumbnail = () => {
    if (isEmpty(image))
      return imageResizer(
        `${appConfig.assetBaseUrl}/placeholder/community-${
          isDesktop ? 'desktop' : 'mobile'
        }-avatar.png`,
        '300w',
      )
    return imageResizer(image, '300w')
  }

  const renderPrimaryTextButton = () => {
    switch (type) {
      case 'creator':
        return t('follow')
      case 'community':
      default:
        return t('join')
    }
  }

  const SecondaryActionButton = () => {
    switch (type) {
      case 'referral':
        return (
          <StyledActionButton
            id="b-user-referral-see-community-profile"
            buttonVariant="outlined"
            sx={{ width: '100%' }}
            onClick={() => {
              setIsOpenSuggestFollowModal(false)
              redirect(`/k/${communityId}`)
              clearReferralCodeInfo()
            }}
            disabled={isLoading}
          >
            Lihat Profil
          </StyledActionButton>
        )
      case 'community':
      case 'creator':
      default:
        return (
          <StyledActionButton
            id="b-social-cancel-suggest-follow"
            buttonVariant="outlined"
            sx={{ width: '100%' }}
            onClick={() => setIsOpenSuggestFollowModal(false)}
            disabled={isLoading}
          >
            {t('negativeSuggestionFollowButton')}
          </StyledActionButton>
        )
    }
  }

  return (
    <>
      <Snackbar
        open={!isEmpty(messageBox)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => setMessageBox('')}
      >
        <Box>
          <MessageBox
            sxContainer={{
              background: theme.palette.background.secondary,
            }}
            open={!isEmpty(messageBox)}
            onClose={() => {
              setMessageBox('')
            }}
            isError={isError}
            message={messageBox}
          />
        </Box>
      </Snackbar>
      <BottomDrawer
        supportDesktop
        open={isOpenSuggestFollowModal}
        id="c-social-suggest-follow-comm-creator-modal"
        boxPadding={'24px'}
        title
        closeButtonId="b-social-close-suggest-follow-comm-creator-modal"
        onCloseIcon={() => {
          if (type === 'referral' && callback) callback()
          setIsOpenSuggestFollowModal(false)
        }}
      >
        <>
          <Stack
            spacing={2}
            justifyContent={'center'}
            alignItems={'center'}
            textAlign={'center'}
          >
            {type === 'creator' ? (
              <Avatar
                src={imageResizer(image, '300w')}
                sx={{
                  width: '124px',
                  height: '124px',
                  marginBottom: '24px',
                }}
              />
            ) : (
              <CommunityImage
                src={renderCommunityThumbnail()}
                sxContainer={{
                  width: '124px',
                  height: '124px',
                }}
              />
            )}
            <Typography
              sx={(theme) => ({
                ...theme.typography.h3,
                color: theme.palette.text.primary,
              })}
            >
              {type !== 'creator'
                ? t('communityFollowSuggestionTitle', {
                    community_name: name,
                  })
                : t('creatorFollowSuggestionTitle', {
                    name: name,
                  })}
            </Typography>
            <Typography
              sx={(theme) => ({
                ...theme.typography.normalRegular,
                color: theme.palette.text.secondary,
              })}
            >
              {renderSubtitle()}
            </Typography>
            <Stack
              direction={'row'}
              spacing={2}
              alignItems={'center'}
              width={'100%'}
            >
              <SecondaryActionButton />
              <StyledActionButton
                id="b-social-accept-suggest-follow"
                fullWidth
                startIcon={<PersonAddAlt1Icon />}
                onClick={
                  type !== 'creator'
                    ? handleClickJoinCommunityWithReferral
                    : submitFollowCreator
                }
                disabled={isLoading}
              >
                {renderPrimaryTextButton()}
              </StyledActionButton>
            </Stack>
          </Stack>
        </>
      </BottomDrawer>
      <ScreeningQuestionDrawer
        onSubmit={(answer) => submitJoinCommunity(answer)}
        communityId={communityId}
        isLoading={isLoading}
        isError={isError}
        onCloseError={() => setIsError(false)}
      />
    </>
  )
}

export default SuggestFollowModal

import { styled } from '@mui/material'
import Box from 'components/commons/Box'

type ICardType = {
  cardType?: string
}
export const IconVisa = styled(Box)<ICardType>(({ cardType }) => ({
  backgroundImage:
    cardType === 'visa'
      ? 'url("/img/card-payment/ic_visa.png")'
      : 'url("/img/card-payment/disabled/ic_visa.png")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  width: '100%',
  height: '24px',
}))
export const IconMasterCard = styled(Box)<ICardType>(
  ({ cardType }) => ({
    backgroundImage:
      cardType === 'mastercard'
        ? 'url("/img/card-payment/ic_mastercard.png")'
        : 'url("/img/card-payment/disabled/ic_mastercard.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: '100%',
    height: '24px',
  }),
)
export const IconJcb = styled(Box)<ICardType>(({ cardType }) => ({
  backgroundImage:
    cardType === 'jcb'
      ? 'url("/img/card-payment/ic_jcb.png")'
      : 'url("/img/card-payment/disabled/ic_jcb.png")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  width: '100%',
  height: '24px',
}))

export const IconWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100px',
  marginLeft: '2px',
  marginBottom: '16px',
})
export const InputWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'stretch',
  gap: '16px',
  marginBottom: '16px',
})

import { CommunityContext } from 'contexts/CommunityContext'
import { useContext } from 'react'

const useCommunity = () => {
  const context = useContext(CommunityContext)

  if (!context) {
    throw new Error(
      'Community context must be use inside CommunityProvider',
    )
  }

  return context
}

export default useCommunity

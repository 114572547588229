const countryLang = [
  {
    id: 'id',
    label: 'Indonesia',
    flag: `ID`,
  },
  {
    id: 'en',
    label: 'English',
    flag: `US`,
  },
]

export default countryLang

import { useRouter } from 'next/router'
import appConfig from 'configs/app'
import isEmpty from 'helpers/is-empty'
import redirect from 'helpers/redirect'
import { getIdToken } from 'helpers/auth'
import {
  ICommunity,
  ICommunityInfo,
} from 'stores/domains/Social/Community/interface'
import { useCommunityStore } from 'stores/domains/Social'
import {
  useCommunityDetail,
  useCommunityInfo,
} from 'hooks/domains/Community'
import { ReactNode, createContext, useEffect } from 'react'

export interface CommunityContextType {
  isError: boolean
  isLoading: boolean
  isSuspended: boolean
  isErrorCommunityInfo: boolean
  isErrorCommunityDetail: boolean
  community: ICommunity
  communityInfo: ICommunityInfo
  errorCode: string
  hasCommunity: boolean
  fetchCommunityInfo: () => void
  fetchCommunityDetail: () => void
}

const CommunityContext = createContext<CommunityContextType | null>(
  null,
)

interface CommunityProps {
  children: ReactNode
}

const PROTECTED_PATH_URLS = [
  '/community/sponsorship',
  '/community/mission',
  '/community/mission/[id]',
]

const CommunityProvider = ({ children }: CommunityProps) => {
  const { query, pathname } = useRouter()
  const idToken = getIdToken()

  const { community, errorCode } = useCommunityStore((state) => ({
    community: state.community,
    errorCode: state.errorCode,
  }))

  const {
    refetch: fetchCommunityInfo,
    isFetching: isLoadingCommunityInfo,
    isError: isErrorCommunityInfo,
    data: communityInfo,
  } = useCommunityInfo()

  const {
    refetch: fetchCommunityDetail,
    isFetching: isLoadingCommunityDetail,
    isError: isErrorCommunityDetail,
  } = useCommunityDetail(communityInfo?.community_id)

  const isLoading = isLoadingCommunityInfo || isLoadingCommunityDetail
  const isError = isErrorCommunityInfo || isErrorCommunityDetail
  const hasCommunity =
    !isLoadingCommunityInfo && communityInfo?.has_community
  const isSuspended =
    errorCode?.toUpperCase() === 'COMMUNITY_SUSPENDED'

  useEffect(() => {
    if (idToken && !communityInfo?.community_id) fetchCommunityInfo()
  }, [idToken, query])

  useEffect(() => {
    if (
      !isLoadingCommunityInfo &&
      communityInfo &&
      communityInfo.has_community &&
      !query.community_id
    ) {
      fetchCommunityDetail()
    }
  }, [communityInfo, isLoadingCommunityInfo, query])

  useEffect(() => {
    if (!PROTECTED_PATH_URLS.includes(pathname)) return
    // prevent open page when user has no community
    if (!isEmpty(communityInfo) && !hasCommunity) {
      redirect(appConfig.communityRegistrationUrl, {
        isReplace: true,
      })
    }
  }, [pathname, communityInfo])

  return (
    <CommunityContext.Provider
      value={{
        isError,
        isLoading,
        isSuspended,
        isErrorCommunityInfo,
        isErrorCommunityDetail,
        fetchCommunityInfo,
        fetchCommunityDetail,
        community,
        errorCode,
        communityInfo,
        hasCommunity,
      }}
    >
      {children}
    </CommunityContext.Provider>
  )
}

export { CommunityContext, CommunityProvider }

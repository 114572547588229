import Stack from 'components/commons/Stack'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import Typography from 'components/commons/Typography'
import { useRouter } from 'next/router'
import { useLocationStore } from 'stores/domains/Social'
import SearchBarWithBackBtn from './SearchWithBackBtn'
import { defaultLocation } from 'constants/common'
import { useTranslation } from 'react-i18next'

const LocationHeaderMobile = () => {
  const theme = useTheme()
  const { isReady } = useRouter()
  const [isOpen, setOpen] = useState(false)
  const { t } = useTranslation()

  const { setSelectedFilterProvince, selectedFilterProvince } =
    useLocationStore((state) => ({
      setSelectedFilterProvince: state.setSelectedFilterProvince,
      selectedFilterProvince: state.selectedFilterProvince,
    }))

  useEffect(() => {
    if (isReady && selectedFilterProvince?.id === null) {
      setSelectedFilterProvince(defaultLocation)
    }
  }, [isReady])

  return (
    <Stack>
      <Stack
        direction={'row'}
        gap={1}
        onClick={() => setOpen(true)}
        sx={{
          cursor: 'pointer',
        }}
      >
        <LocationOnIcon color="secondary" />
        {selectedFilterProvince?.name !== '' && (
          <Typography
            sx={{
              fontWeight: 700,
              color: theme.palette.text.secondary,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '50px',
            }}
          >
            {t(selectedFilterProvince.name)}
          </Typography>
        )}
      </Stack>
      <SearchBarWithBackBtn
        showPage={isOpen}
        onCloseAction={() => setOpen(false)}
      />
    </Stack>
  )
}

export default LocationHeaderMobile

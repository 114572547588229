import { useState } from 'react'
import { useRouter } from 'next/router'
import {
  BottomNavigation,
  BottomNavigationAction,
  Divider,
  useTheme,
} from '@mui/material'

import Stack from 'components/commons/Stack'
import BottomDrawer from 'components/commons/BottomDrawer/v2'
import CreateMenuList from 'components/commons/Header/CreateMenuList'

import authConfig from 'configs/auth'
import redirect from 'helpers/redirect'
import objectToQueryString from 'helpers/transform/object-to-query-string'
import { getIdToken } from 'helpers/auth'
import { getStateFromSource, trackEvent } from 'helpers/analytics'
import { useProfileUserStore } from 'stores/domains/User'
import isEmpty from 'helpers/is-empty'

import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded'
import ExploreRoundedIcon from '@mui/icons-material/ExploreRounded'
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded'
import ShopTwoRoundedIcon from '@mui/icons-material/ShopTwoRounded'
import {
  IconTiptipSmall,
  ExploreActive,
  LibraryActive,
  MenuActive,
} from 'svgs/icons'
import { redirectWithReferrer } from 'helpers/redirector'
import { useTranslation } from 'react-i18next'
import { getCookie } from 'helpers/cookie'

type ActiveTypes = 'home' | 'browse' | 'library' | 'menu' | 'create'

interface URLProps {
  url: string
  params?: object
}

function MobileNavigation(): JSX.Element {
  const { t } = useTranslation()
  const idToken = getIdToken()
  const theme = useTheme()
  const { pathname } = useRouter()
  const { profileData } = useProfileUserStore((state) => ({
    profileData: state.profileData,
  }))

  const excludeUrls = [
    '/session',
    '/content',
    '/event',
    '/booking',
    '/message',
    '/notification',
    '/setting',
    '/p',
    '/community',
    '/creator/dashboard',
    '/[role_type]/create',
  ]

  const whitelistUrls = ['/event/purchased', '/event/verification']

  const urlMaps = new Map([
    ['/', 'home'],
    ['/browse', 'browse'],
    [idToken ? '/library' : '/event/purchased', 'library'],
    ['/menu', 'menu'],
  ])
  const activeTab = urlMaps.get(pathname)
  const [isOpenContent, setIsOpenContent] = useState(false)
  const [selectedTab, setSelectedTab] = useState(activeTab)

  const hasBookingAccessToken = getCookie('book_token')
  const urls: Partial<Record<ActiveTypes, URLProps>> = {
    home: { url: '/' },
    browse: { url: '/browse', params: { status: 'all' } },
    library: {
      url: !isEmpty(idToken)
        ? '/library'
        : hasBookingAccessToken
        ? '/event/purchased'
        : '/event/verification',
    },
    menu: { url: '/menu' },
  }

  const trackerEvent = (value: ActiveTypes) => {
    const eventProperties = {
      state_name: getStateFromSource(pathname),
    }
    const eventNames: Record<ActiveTypes, string> = {
      home: 'click_home',
      browse: 'click_browse_search',
      create: 'click_create_product',
      library: 'click_library',
      menu: 'click_navigation_menu',
    }
    trackEvent(eventNames[value], eventProperties)
  }

  const handleChangeNav = (value: ActiveTypes) => {
    trackerEvent(value)
    setSelectedTab(value)
    if (value === 'create') {
      if (idToken) {
        setIsOpenContent(true)
      } else {
        redirectWithReferrer(authConfig.loginPath)
      }
    } else {
      const params = {
        ...urls[value]?.params,
        source: pathname,
        source_action: 'trigger_home_tab',
      }
      const targetUrl = `${urls[value].url}?${objectToQueryString(
        params,
      )}`
      redirect(targetUrl)
    }
  }

  if (
    excludeUrls.some((skipUrl) => {
      return (
        String(pathname).includes(skipUrl) &&
        !whitelistUrls.includes(pathname)
      )
    })
  ) {
    return null
  }

  return (
    <Stack p={0} m={0}>
      <BottomNavigation
        showLabels
        value={selectedTab}
        onChange={(_, newValue) => {
          handleChangeNav(newValue as ActiveTypes)
        }}
        sx={{
          ...theme.typography.smallRegular,
          height: '78px',
          display: { xs: 'flex', md: 'none' },
          zIndex: 10,
          position: 'fixed',
          bottom: 0,
          left: 0,
          top: 'auto',
          width: '100%',
          '& button.Mui-selected': {
            borderTop: `2px solid ${theme.palette.tiptip[500]}`,
          },
          '& .Mui-selected, .Mui-selected': {
            color: theme.palette.orange[500],
            svg: {
              borderTop: 'none',
              fill: theme.palette.orange[500],
            },
          },
        }}
      >
        <BottomNavigationAction
          id="b-common-footer-home-button"
          label="Home"
          icon={<IconTiptipSmall active={selectedTab === 'home'} />}
          value="home"
        />
        <BottomNavigationAction
          id="b-common-footer-explore-button"
          label="Explore"
          icon={
            selectedTab === 'browse' ? (
              <ExploreActive />
            ) : (
              <ExploreRoundedIcon />
            )
          }
          value="browse"
        />
        {profileData?.isCreator && (
          <BottomNavigationAction
            id="b-common-footer-create-session-button"
            label="Create"
            icon={
              <AddBoxRoundedIcon
                sx={{
                  fill:
                    selectedTab === 'create' &&
                    theme.palette.orange[500],
                }}
              />
            }
            value="create"
          />
        )}
        <BottomNavigationAction
          id="b-common-footer-library-history-button"
          label={t('purchase')}
          icon={
            selectedTab === 'library' ? (
              <LibraryActive />
            ) : (
              <ShopTwoRoundedIcon />
            )
          }
          value="library"
        />
        <BottomNavigationAction
          id="b-common-footer-menu-button"
          label="Menu"
          icon={
            selectedTab === 'menu' ? (
              <MenuActive />
            ) : (
              <GridViewRoundedIcon />
            )
          }
          value="menu"
        />
      </BottomNavigation>
      <BottomDrawer
        open={isOpenContent}
        title="Buat Konten"
        onClose={() => setIsOpenContent(false)}
      >
        <CreateMenuList
          p={0}
          divider={
            <Divider
              sx={(theme) => ({
                background: theme.palette.background.tertiery,
              })}
            />
          }
        />
      </BottomDrawer>
    </Stack>
  )
}

export default MobileNavigation

import { Menu, MenuItem, Typography, useTheme } from '@mui/material'
import Button from 'components/commons/Button'
import Stack from 'components/commons/Stack'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import appConfig from 'configs/app'
import { getLocalStorage } from 'helpers/local-storage'
import countryLang from 'constants/language'
import Flag from 'react-country-flag'

const MultipleLanguageMenu = () => {
  const theme = useTheme()
  const { i18n } = useTranslation()

  const [currentLanguage, setCurrentLanguage] = useState(
    getLocalStorage('i18nextLng') || appConfig.defaultLang,
  )

  const [selectedLang, setSelectedLang] = useState({
    id: '',
    label: '',
    flag: '',
  })

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSelectLanguage = (val) => {
    setCurrentLanguage(val.id)
    i18n.changeLanguage(val.id) // Change the language without modifying the URL
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const languageContent = (val) => {
    return (
      <Stack
        direction={'row'}
        sx={{
          justifyContent: 'space-around',
          textAlign: 'center',
          gap: 1,
          img: {
            alignSelf: `center`,
          },
        }}
      >
        <Flag
          countryCode={val?.flag}
          svg
          style={{
            width: '16px',
          }}
        />
        <Typography>{val?.label}</Typography>
      </Stack>
    )
  }

  useEffect(() => {
    const selectedLanguage = countryLang.find(
      (language) => language.id === currentLanguage,
    )
    setSelectedLang(selectedLanguage)
  }, [currentLanguage])

  return (
    <Stack>
      <Button
        id="b-common-language-option"
        buttonVariant="nude"
        variant="text"
        sx={{
          color: theme.palette.text.secondary,
          fontSize: `14px`,
          fontWeight: `400`,
        }}
        onClick={handleClick}
      >
        {languageContent(selectedLang)}
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
      >
        {countryLang.map((item, _index) => (
          <MenuItem
            id={`s-language-item-${item.id}`}
            key={`language-${item.id}`}
            onClick={() => {
              handleSelectLanguage(item)
            }}
          >
            {languageContent(item)}
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  )
}

export default MultipleLanguageMenu

import { defaultLocation } from 'constants/common'
import { StateCreator } from 'zustand'
import { ILocation, ILocationStateDefault } from './interface'

export const resetters: (() => void)[] = []

const INITIAL_DATA: ILocationStateDefault = {
  isLoading: false,
  isError: false,
  errorMessage: '',
  selectedFilterProvince: {
    ...defaultLocation,
    name: 'allLocation',
  },
  filterProvinceList: [],
  selectedProvinceHome: {
    ...defaultLocation,
    name: 'allLocation',
  },
  provinceListHome: [],
  selectedCity: {
    ...defaultLocation,
    name: 'allLocation',
  },
  cityList: [],
}

export const createLocationSlice: StateCreator<ILocation> = (set) => {
  resetters.push(() => set(INITIAL_DATA))
  return {
    ...INITIAL_DATA,
    setFilterProvinceList(data) {
      set({
        filterProvinceList: data,
      })
    },
    setSelectedFilterProvince(data) {
      set({
        selectedFilterProvince: data,
      })
    },
    setProvinceListHome(data) {
      set({
        provinceListHome: data,
      })
    },
    setSelectedProvinceHome(data) {
      set({
        selectedProvinceHome: data,
      })
    },
    setCityList(data) {
      set({
        cityList: data,
      })
    },
    setSelectedCityList(data) {
      set({
        selectedCity: data,
      })
    },
    setError(message: string) {
      set({
        isError: true,
        errorMessage: message,
      })
    },
    setLoading(isLoading: boolean) {
      set({
        isLoading,
      })
    },
    clearError() {
      set({
        isError: false,
        errorMessage: '',
      })
    },
    resetSelectedLocation() {
      set({
        selectedFilterProvince: {
          id: null,
          name: 'allLocation',
          thumbnail_image: '',
        },
      })
    },
    resetState() {
      set(INITIAL_DATA)
    },
  }
}

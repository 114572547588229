import Stack from 'components/commons/Stack'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  IconButton,
  InputAdornment,
  styled,
  TextField,
  useTheme,
} from '@mui/material'
import ModalDrawer from 'components/domains/User/ModalDrawer'
import { useEffect, useState } from 'react'
import Typography from 'components/commons/Typography'
import { useFilterProvinceList } from 'hooks/domains/Social'
import { useRouter } from 'next/router'
import { useLocationStore } from 'stores/domains/Social'
import Chip from 'components/commons/Chip'
import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'
import StateImage from 'components/commons/StateImage'
import Attention from 'components/commons/Attention/v2'
import { defaultLocation } from 'constants/common'
import isEmpty from 'helpers/is-empty'

const ProvinceWrapper = styled('div')({
  '>div': {
    margin: '6px 4px',
  },
})

const LocationHeader = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { isReady, query } = useRouter()
  const [isOpen, setOpen] = useState(false)
  const DEFAULT = {
    ...defaultLocation,
    name: t('allLocation'),
  }
  const {
    data: filterProvinceList,
    refetch: getfilterProvinceList,
    isError,
    isFetching,
  } = useFilterProvinceList('', 1, 10)

  const { setSelectedFilterProvince, selectedFilterProvince } =
    useLocationStore((state) => ({
      setSelectedFilterProvince: state.setSelectedFilterProvince,
      selectedFilterProvince: state.selectedFilterProvince,
    }))

  const [searchTerm, setSearchTerm] = useState('')

  const filteredProvince = filterProvinceList?.filter((province) =>
    province?.name.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  function searchComponent() {
    return (
      <TextField
        type="text"
        variant="outlined"
        placeholder={t('searchHintFilterLocations')}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                sx={{
                  background: theme.palette.primary.main,
                  width: '38px',
                  height: '38px',
                }}
              >
                <SearchIcon
                  sx={{
                    color: theme.palette.secondary.main,
                  }}
                />
              </IconButton>
            </InputAdornment>
          ),
          sx: {
            borderRadius: 50,
            background: theme.palette.background.tertiery,
            maxHeight: '44px',
            fontSize: '14px',
            paddingRight: `0px !important`,
          },
        }}
        fullWidth
      />
    )
  }

  const EmptyComponent = () => {
    return (
      <Attention
        sx={{ padding: { xs: '32px 0px' } }}
        title={t('keyCouldntFindLocation')}
        subtitle={t('keyTheLocationYouAre')}
        image={<StateImage type="empty-search" />}
      />
    )
  }

  const ErrorComponent = () => {
    return (
      <Attention
        sx={{ padding: { xs: '32px 0px' } }}
        title={t('otherApiErrorTitle')}
        subtitle={t('otherApiErrorSubtitle')}
        image={<StateImage type="error" />}
      />
    )
  }

  const handleClick = (item) => {
    setSelectedFilterProvince(item)
    setOpen(false)
  }

  useEffect(() => {
    if (isReady) {
      setSearchTerm('')
      getfilterProvinceList()
    }
  }, [isReady])

  useEffect(() => {
    if (isReady && selectedFilterProvince?.id === null) {
      setSelectedFilterProvince(DEFAULT)
    }
  }, [isReady, t])

  useEffect(() => {
    if (
      isReady &&
      !isEmpty(query?.province_id) &&
      filterProvinceList
    ) {
      const provice = filterProvinceList?.find(
        (item) => item.id === Number(query?.province_id),
      )

      setSelectedFilterProvince(provice)
    }
  }, [isReady, query?.province_id, filterProvinceList])

  return (
    <Stack>
      <Stack
        direction={'row'}
        gap={1}
        onClick={() => {
          setOpen(true)
          setSearchTerm('')
        }}
        sx={{
          cursor: 'pointer',
        }}
      >
        <LocationOnIcon sx={{ color: theme.palette.primary.main }} />
        <Typography
          sx={{
            fontWeight: 700,
            color: theme.palette.text.secondary,
          }}
        >
          {t(selectedFilterProvince?.name)}
        </Typography>
        <ExpandMoreIcon
          sx={{
            color: theme.palette.icon.tertiery,
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        />
      </Stack>
      <ModalDrawer
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        title={t('searchHintFilterLocations')}
        sxDialogContainer={{
          '& .MuiDialogContent-root': {
            padding: 0,
          },
          '& .MuiDialog-paper': {
            width: '500px',
          },
        }}
      >
        <Stack spacing={0} p={1.5} gap={2.5}>
          {searchComponent()}
          <Typography variant="h4">
            {t('titleFilterAllLocation')}
          </Typography>
          {isError && <ErrorComponent />}
          {isFetching && <>Loading ...</>}
          {!isError && !isFetching && (
            <ProvinceWrapper
              sx={{
                gap: '24px',
                mt: 2,
                mb: 2,
                height: {
                  xs: '350px',
                  md: '456px',
                },
                overflowX: 'auto',
              }}
            >
              {searchTerm === '' && (
                <Chip
                  label={t('allLocation')}
                  avatar={
                    <LocationOnIcon
                      sx={{
                        color:
                          selectedFilterProvince?.id === null
                            ? 'secondary'
                            : `${theme.palette.primary.main} !important`,
                        backgroundColor: 'unset !important',
                      }}
                    />
                  }
                  onClick={() => {
                    handleClick(DEFAULT)
                  }}
                  sx={{
                    padding: '8px',
                  }}
                  color={
                    selectedFilterProvince?.id === null
                      ? 'primary'
                      : 'default'
                  }
                />
              )}

              {filteredProvince?.map((item) => (
                <Chip
                  id={`b-location-province-${item.name}`}
                  label={item?.name}
                  onClick={() => {
                    handleClick(item)
                  }}
                  key={item.id}
                  sx={{
                    padding: '8px',
                  }}
                  color={
                    selectedFilterProvince?.id === item.id
                      ? 'primary'
                      : 'default'
                  }
                />
              ))}

              {filteredProvince?.length === 0 && <EmptyComponent />}
            </ProvinceWrapper>
          )}
        </Stack>
      </ModalDrawer>
    </Stack>
  )
}

export default LocationHeader
